import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import saving_animation_icon from "./assets/icon/saving_animation_icon.svg";
import "./App.css";
import {
  reCaptchaVerifier,
  useAuthSubscriber
} from "./services/authentication";
import { connect } from "react-redux";
import { scanQrActions } from "./redux/sagas/scanQrSaga";
import Modal from "./front-end-global-components/components/Modal/Modal";
import InputBox from "./front-end-global-components/components/InputBox/InputBox";
import ConditionalRender from "./front-end-global-components/components/ConditionalRender/ConditionalRender";
import OTPInputBox from "./front-end-global-components/components/OTPInputBox/OTPInputBox";
import Button from "./front-end-global-components/components/Button/Button";
import { authActions } from "./redux/sagas/authSaga";
import {
  useConnectionsListener,
  useProfileListener
} from "./services/database";
import RoundedProfilePicture from "./front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import {
  ArrowIcon,
  TickIcon,
  LogoutIcon
} from "./front-end-global-components/assets/assets";
import { connectionActions } from "./redux/sagas/connectionSaga";
import SnackBar from "./front-end-global-components/components/SnackBar/SnackBar";
import Header from "./front-end-global-components/components/Header/Header";

function App(props) {
  const [status, setStatus] = useState(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const isAuthed = useAuthSubscriber();
  const [showCurrentClinic, setShowCurrentClinic] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null
  });

  const getWindowSize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", getWindowSize);
    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
    // eslint-disable-next-line
  }, []);

  //useEffect for error handling
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  useProfileListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useConnectionsListener({
    uid: props.profile.data && Object.keys(props.profile.data)[0],
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  const setOTPValue = (otp) => {
    if (loginFormData.phoneNumber !== null) {
      setLoginFormData({
        ...loginFormData,
        OTP: otp
      });
    }
  };

  const loginFormChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    props.sendOtp(loginFormData.phoneNumber, "login");
  };

  const otpVerifyHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp("login");
    } else {
      props.verifyOtp(loginFormData.OTP);
    }
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof loginFormData.phoneNumber === "string" &&
      loginFormData.phoneNumber.trim().length >= 13
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (typeof props.auth.data.uid === "string") {
      setShowLoginModal(false);
    } else {
      setShowLoginModal(true);
    }
  }, [props.auth.data.uid]);

  useEffect(() => {
    if (props.connection.data) {
      props.setCurrentConnection(
        props.connection.data &&
          props.connection.data[props.connection.data.length - 1].connectionId
      );
    }
    // eslint-disable-next-line
  }, [props.connection.data]);

  return (
    <>
      {
        <div className="inherit-parent-height position-relative">
          {props.scanQr?.stepperState &&
          props.scanQr?.stepperState?.patient === true ? (
            <ConnectionSuccessComponent
              backButtonClick={() => {
                props.setStepperState({
                  patient: false
                });
              }}
            />
          ) : (
            <section className="inherit-parent-height flex-direction-column ">
              <Header
                removeResponsive={true}
                headerPositionLeft={true}
                hideBackButton={true}
                maxStepperCount={0}
                // onClick={() => {
                //   setShouldShowFooterTimerBar((prevState) => !prevState);
                // }}
                currentStepperValue={0}
                supportIconOnClick={() => {}}
              />
              {props.auth.data.uid && !props.scanQr.loading ? (
                <main
                  style={{
                    flexBasis: "100%"
                  }}
                  className="position-relative container-height"
                >
                  <p className="padding-left-medium text-align-center padding-bottom-default padding-top-default font-family-gilroy-medium font-size-medium ">
                    Scan your patient’s QR code
                  </p>
                  <QrReader
                    constraints={{ facingMode: "environment" }}
                    key="environment"
                    onResult={(result, error) => {
                      if (!!result && props.scanQr.loading === false) {
                        if (result && result.text) {
                          if (props.scanQr.isTimeExpired === true) {
                            return;
                          } else {
                            props.setOnQrDetected(result.text);
                          }
                        } else if (result && result.t) {
                          if (props.scanQr.isTimeExpired === true) {
                            return;
                          }
                          props.setOnQrDetected(result.t);
                        }
                      }
                    }}
                    className="inherit-parent-height "
                    containerStyle={{
                      height: "100%",
                      paddingTop: "0"
                    }}
                    videoContainerStyle={{
                      height: "100%",
                      paddingTop: "0"
                    }}
                    videoStyle={{
                      position: "none",
                      width: "none"
                    }}
                    style={{ height: "100%" }}
                    // ViewFinder={<div>hello</div>}
                  />
                </main>
              ) : (
                <div
                  className={`${"remaining-height"} background-color-black`}
                ></div>
              )}
            </section>
          )}
          {props.scanQr.loading === true && (
            <div className="position-absolute-center-self inherit-parent-height inherit-parent-width dimmed-overlay font-size-medium flex-place-children-page-center ">
              <div className="background-color-white padding-left-medium padding-right-medium padding-top-default padding-bottom-default border-radius-default ">
                <div className="loader" />
              </div>
            </div>
          )}
          {props.auth.data.uid && (
            <div className="background-white padding-larger inherit-parent-width position-absolute bottom-0">
              {showCurrentClinic && props.auth.data.uid && (
                <Modal
                  show={showCurrentClinic}
                  canIgnore={true}
                  onClose={() => {
                    setShowCurrentClinic(!showCurrentClinic);
                  }}
                  position={
                    props.windowInnerWidth > 576
                      ? ""
                      : "position-fixed bottom-0 left-0 right-0"
                  }
                  width="inherit-parent-width"
                  maxWidth={
                    props.windowInnerWidth < 576 ? "" : "max-width-500px"
                  }
                  background="false"
                  boxShadow="false"
                  borderRadius="false"
                  height="height-fit-to-content"
                >
                  <div
                    data-cy="switcher-modal"
                    className={`background-white padding-left-larger padding-bottom-larger padding-top-larger padding-right-default ${
                      props.windowInnerWidth < 576
                        ? "border-radius-top-default"
                        : "border-radius-default"
                    } box-shadow-default font-family-gilroy-regular font-color-secondary`}
                  >
                    <div
                      className={`${
                        props.connection.data &&
                        props.connection.data.length > 3
                          ? "height-224px overflow-y-scroll overflow-x-hidden"
                          : ""
                      }`}
                    >
                      {props.connection.currentConnection &&
                        props.connection.data &&
                        props.connection.data.map((data, index) => (
                          <ProfileCards
                            connection={props.connection}
                            selected={
                              props.connection.currentConnection ===
                              data.connectionId
                            }
                            key={index}
                            data={data}
                            onCardClick={() => {
                              if (
                                props.connection.currentConnection !==
                                data.connectionId
                              ) {
                                props.setCurrentConnection(data.connectionId);
                                setShowCurrentClinic(!showCurrentClinic);
                              }
                            }}
                          />
                        ))}

                      <ProfileCards
                        type="logout"
                        text="Logout"
                        addOnClick={() => {
                          setShowLogoutModal(true);
                          setShowCurrentClinic(false);
                        }}
                      />
                    </div>
                  </div>
                </Modal>
              )}

              <ClinicSwitcherCard
                connection={props.connection}
                data={props.connection.data?.find(
                  (data) =>
                    data.connectionId === props.connection.currentConnection
                )}
                showCurrentClinic={showCurrentClinic}
                iconClassName={`${false ? "rotate-90" : "rotate-270"}`}
                onClick={() => {
                  setShowCurrentClinic(!showCurrentClinic);
                }}
              />
            </div>
          )}
        </div>
      }
      {/* LOGIN MODAL */}
      <Modal
        show={showLoginModal}
        canIgnore={false}
        onClose={() => {
          setShowLoginModal(!showLoginModal);
        }}
        position={
          windowInnerWidth > 576 ? "" : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={windowInnerWidth < 576 ? "" : "max-width-500px"}
        RemoveBlackOverlay={true}
        background="true"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <form
          onChange={(event) => {
            loginFormChange(event);
          }}
          onSubmit={(event) => event.preventDefault()}
          className={`background-white padding-larger ${
            windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
            LOGIN WITH YOUR CLINIC PHONE NUMBER
          </h1>
          <InputBox
            className="inherit-parent-width border-radius-default margin-vertical-large"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            value={loginFormData.phoneNumber}
            removeResponsive={true}
            buttonTileId="login-button"
            buttonTile={
              props.auth.credentials.verificationId !== null
                ? "Resend OTP"
                : "Get OTP"
            }
            buttonType={
              typeof props.auth.credentials.verificationId === "string"
                ? "button"
                : "submit"
            }
            onButtonClick={(event) => {
              otpRequestHandler(event);
            }}
            data-cy="login-screen-phone-number-field"
            required
            suffixButtonDisabled={isOtpButtonDisabled()}
          />
          <ConditionalRender
            condition={
              typeof props.auth.credentials.verificationId === "string"
            }
          >
            <OTPInputBox
              name="otp"
              setValue={setOTPValue}
              className="margin-bottom-larger"
              required
            />
          </ConditionalRender>
          <Button
            data-cy={
              props.auth.loading === true
                ? "login-loading-button"
                : "login-button"
            }
            autofocus={true}
            loading={props.auth.loading}
            type={
              typeof props.auth.credentials.verificationId === "string"
                ? "submit"
                : "button"
            }
            text="Login"
            onClick={(event) => {
              otpVerifyHandler(event);
            }}
            disabled={
              !(
                typeof loginFormData?.OTP === "string" &&
                loginFormData?.OTP.length === 6
              )
            }
            boxShadow={false}
          />
        </form>
      </Modal>
      <Modal
        show={showLogoutModal}
        canIgnore={true}
        onClose={() => {
          setShowLogoutModal(!showLogoutModal);
        }}
        position={
          windowInnerWidth > 576 ? "" : "position-fixed bottom-0 left-0 right-0"
        }
        width="inherit-parent-width"
        maxWidth={windowInnerWidth < 576 ? "" : "max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          data-cy="logout-confirmation-modal"
          className={`background-white padding-larger ${
            windowInnerWidth < 576
              ? "border-radius-top-default"
              : "border-radius-default"
          } box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className="font-family-gilroy-medium text-align-center margin-bottom-larger padding-top-large">
            Are you sure you want to logout?
          </div>

          <div className=" flex-justify-content-space-around padding-top-medium margin-bottom-large">
            <Button
              data-cy="confirm-logout-button"
              text="Logout"
              type="button"
              variant="danger"
              className=" margin-right-large"
              boxShadow={false}
              onClick={() => {
                props.logout();
                setShowLogoutModal(false);
              }}
            />
            <Button
              data-cy="logout-cancel-button"
              text="Cancel"
              variant="secondary"
              className=" margin-left-large"
              boxShadow={false}
              onClick={() => {
                setShowLogoutModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
      <div className=" display-none" id="login-button"></div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
    connection: state.connection,
    scanQr: state.scanQr
  };
};

const mapDispatchToProps = function () {
  return {
    setStepperState: (data) => scanQrActions.setStepperState(data),
    setOnQrDetected: (data) => scanQrActions.setOnQrDetected(data),
    setScannerStatus: (data) => scanQrActions.setScannerStatus(data),
    setIsTimeExpired: (data) => scanQrActions.setIsTimeExpired(data),
    logout: () => authActions.logout(),
    verifyOtp: (loginOtp) => authActions.verifyOtp(loginOtp),
    sendOtp: (phoneNumber, type) => authActions.sendOtp(phoneNumber, type),
    setCurrentConnection: (data) => connectionActions.setCurrentConnection(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const ConnectionSuccessComponent = (props) => {
  return (
    <section className="inherit-parent-height flex-place-children-page-center padding-larger font-size-medium ">
      <img
        src={saving_animation_icon}
        alt="saving_animation_icon "
        className="height-width-1-em"
      />
      <p className="padding-vertical-medium font-family-gilroy-medium font-color-secondary font-size-medium">
        Connection successful!
      </p>
      <p className="text-align-center padding-bottom-larger font-family-gilroy-regular font-color-secondary font-size-medium">
        Additions features & services unlocked in clinics.nintoapp.com
      </p>
      <button
        onClick={props.backButtonClick}
        className="padding-medium border-radius-default padding-left-larger padding-right-larger background-color-primary font-color-white"
      >
        Okay
      </button>
    </section>
  );
};

const ClinicSwitcherCard = (props) => {
  return (
    <div
      data-cy={`${props.connection?.currentConnection}-clinic-switcher`}
      className={`${
        props.showCurrentClinic ? "padding-top-large" : ""
      } display-flex  flex-justify-content-space-between`}
      onClick={props.onClick}
    >
      <div className=" display-flex flex-center-children-vertically">
        <RoundedProfilePicture
          size="medium"
          userType="corporate"
          removeResponsive={true}
          src={
            props.connection?.currentConnection &&
            props.connection?.brandsLogo &&
            props.data?.brandId
              ? props.connection?.brandsLogo[props.data?.brandId]
              : ""
          }
        />
        <div className="padding-left-large">
          <div className=" font-size-large padding-right-default">
            {props.data?.companyName ? props.data?.companyName : "Company"}
          </div>
          <div className=" font-size-small">{props.data?.locality}</div>
        </div>
      </div>

      <div
        data-cy="drop-down-button"
        className={`${
          props.iconClassName ? props.iconClassName : " "
        } padding-right-medium flex-center-children-vertically`}
      >
        <ArrowIcon />
      </div>
    </div>
  );
};

const ProfileCards = (props) => {
  return (
    <>
      {!props.type && (
        <div
          data-cy={`${
            props.data?.connectionId
              ? props.data.connectionId + "-card"
              : props.data.documentId
              ? props.data.documentId + "-card"
              : props.data.name + "-card"
          }`}
          className={`inherit-parent-width flex-justify-content-space-between margin-bottom-large border-radius-default cursor-pointer`}
          onClick={props.onCardClick}
        >
          <div className="flex-center-children-vertically ">
            <RoundedProfilePicture
              size="medium"
              userType="corporate"
              removeResponsive={true}
              src={
                props.connection.brandsLogo &&
                props.connection.currentConnection &&
                props.data.brandId
                  ? props.connection.brandsLogo[props.data.brandId]
                  : ""
              }
            />
            <div className="padding-left-large">
              <div className=" font-size-large padding-right-default">
                {props.data?.companyName
                  ? props.data?.companyName
                  : props.data.name}
              </div>
              <div className=" font-size-small">{props.data.locality}</div>
            </div>
          </div>
          <ConditionalRender condition={props.selected === true}>
            <div className="padding-right-medium flex-center-children-vertically">
              <TickIcon color="#00A000" />
            </div>
          </ConditionalRender>
          <ConditionalRender condition={props.selected === false}>
            <div className="padding-right-medium flex-center-children-vertically">
              <ArrowIcon />
            </div>
          </ConditionalRender>
        </div>
      )}

      {props.type === "logout" && (
        <div
          data-cy="logout-card"
          className="flex-justify-content-space-between flex-center-children-vertically cursor-pointer"
          onClick={props.addOnClick}
        >
          <div className=" display-flex flex-center-children-vertically ">
            <div className="background-color-lite-grey border-radius-100-percentage height-and-width-44px flex-place-children-page-center">
              <LogoutIcon color={"red"} />
            </div>
            <div className="font-size-large padding-left-large">
              {props.text}
            </div>
          </div>
          <div
            className={`padding-right-medium flex-center-children-vertically`}
          >
            <ArrowIcon />
          </div>
        </div>
      )}
    </>
  );
};
