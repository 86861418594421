import PropTypes from "prop-types";

function ConditionalRender(props) {
  return !!props.condition === true ? props.children : null;
}

ConditionalRender.propTypes = {
  /**
   * give the condition`s result
   */
  condition: PropTypes.bool
};

//Default props
ConditionalRender.defaultProps = {
  condition: undefined
};

export default ConditionalRender;
