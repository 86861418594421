const statusReducer = (
  state = {
    code: null,
    message: null
  },
  action
) => {
  switch (action.type) {
    case "SET_STATUS":
      return {
        code: action.payload.code,
        message: action.payload.message
      };
    case "REMOVE_STATUS":
      return {
        code: null,
        message: null
      };
    case "RESET":
      return {
        code: null,
        message: null
      };
    default:
      return state;
  }
};
export default statusReducer;
