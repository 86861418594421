import React, { useState, useRef } from "react";
import default_user_img from "../../assets/default_user.svg";
import default_corporate_img from "../../assets/defaultCorporatePic.svg";
import edit from "../../assets/Edit.svg";
import "./RoundedProfilePicture.css";
import "../../design-system.css";
import PropTypes from "prop-types";

function RoundedProfilePicture(props) {
  const [isUnableToDownloadImage, setIsUnableToDownloadImage] = useState(false);
  const profileInputElementRef = useRef(null);

  function fileHandler(event) {
    if (event.target.files[0]) {
      props.setFile(URL.createObjectURL(event.target.files[0]));
    }
  }

  const getImageSize = () => {
    let className;
    switch (props.size) {
      case "smaller":
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_24px"
        } height_and_width_24px border-radius-100-percentage border-2px-grey background-white `;
        break;

      case "small":
        className = `${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-small-height-width"
        } rounded-profile-picture-small-height-width border-radius-100-percentage border-2px-grey background-white`;
        break;

      case "medium":
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_44px"
        } height_and_width_44px border-radius-100-percentage border-2px-grey background-white`;
        break;

      case "large":
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_84px"
        } height_and_width_84px border-radius-100-percentage border-2px-grey background-white`;
        break;

      default:
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_24px"
        } height_and_width_24px`;
        break;
    }
    return className;
  };

  const getShimmerSize = () => {
    let className;
    switch (props.size) {
      case "smaller":
        className = `shimmer ${
          props.removeResponsive ? "" : "adaptive-height_and_width_24px"
        } height_and_width_24px border-radius-100-percentage border-2px-grey`;
        break;

      case "small":
        className = `shimmer ${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-small-height-width"
        } rounded-profile-picture-small-height-width border-radius-100-percentage border-2px-grey`;
        break;

      case "medium":
        className = `shimmer ${
          props.removeResponsive ? "" : "adaptive-height_and_width_44px"
        } height_and_width_44px border-radius-100-percentage border-2px-grey`;
        break;

      case "large":
        className = `shimmer ${
          props.removeResponsive ? "" : "adaptive-height_and_width_84px"
        } height_and_width_84px border-radius-100-percentage border-2px-grey`;
        break;

      default:
        className = `shimmer ${
          props.removeResponsive ? "" : "adaptive-height_and_width_24px"
        } height_and_width_24px`;
        break;
    }
    return className;
  };

  const getFontSize = () => {
    let className;
    switch (props.size) {
      case "smaller":
        className = `${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-display-letter-font-size-smaller"
        } rounded-profile-picture-display-letter-font-size-smaller`;
        break;

      case "small":
        className = `${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-display-letter-font-size-small"
        } rounded-profile-picture-display-letter-font-size-small`;
        break;

      case "medium":
        className = `${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-display-letter-font-size-medium"
        } rounded-profile-picture-display-letter-font-size-medium`;
        break;

      default:
        className = `${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-display-letter-font-size"
        } rounded-profile-picture-display-letter-font-size`;
        break;
    }
    return className;
  };

  const getEditIconSize = () => {
    let className;
    switch (props.size) {
      case "smaller":
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_24px"
        } height_and_width_24px border-radius-100-percentage border-2px-grey background-white `;
        break;

      case "small":
        className = `${
          props.removeResponsive
            ? ""
            : "adaptive-rounded-profile-picture-small-height-width"
        } rounded-profile-picture-small-height-width border-radius-100-percentage border-2px-grey background-white`;
        break;

      case "medium":
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_44px"
        } height_and_width_44px border-radius-100-percentage border-2px-grey background-white `;
        break;

      case "large":
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_84px"
        } height_and_width_84px border-radius-100-percentage border-2px-grey background-white`;
        break;

      default:
        className = `${
          props.removeResponsive ? "" : "adaptive-height_and_width_24px"
        } height_and_width_24px`;
        break;
    }
    return className;
  };

  return (
    <>
      <div className={`${getEditIconSize()} border-none position-relative`}>
        {props.loading === true ? (
          <div
            className={`${
              props.className ? props.className : ""
            } ${getShimmerSize()}`}
            data-cy={"profile-image-loader"}
          ></div>
        ) : (
          <div className="position-relative ">
            {typeof props.src === "string" &&
            isUnableToDownloadImage === false ? (
              <div className="inherit-parent-height flex-place-children-page-center">
                <img
                  onClick={props.onClick}
                  data-cy={
                    props["data-cy"] ? props["data-cy"] : "profile-image"
                  }
                  src={props.src}
                  className={` ${
                    props.className ? props.className : ``
                  } cursor-pointer ${getImageSize()}`}
                  alt={props.alt ? props.alt : ""}
                  onError={(_) => {
                    setIsUnableToDownloadImage(true);
                  }}
                />
              </div>
            ) : props.src === null &&
              typeof props.displayName === "string" &&
              props.displayName.trim().length > 0 &&
              typeof props.background === "string" &&
              props.background.trim().length > 0 ? (
              <div
                data-cy={props["data-cy"] ? props["data-cy"] : "profile-image"}
                className={`${getImageSize()} text-align-center flex-center-children-horizontally flex-align-items-center font-family-gilroy-regular `}
                style={{ backgroundColor: `${props.background}` }}
                onClick={props.onClick}
              >
                <span
                  className={`inherit-parent-height font-color-white text-transform-uppercase ${getFontSize()}`}
                >
                  {props.displayName.trim().split("")[0]}
                </span>
              </div>
            ) : (
              <img
                onClick={props.onClick}
                data-cy={props["data-cy"] ? props["data-cy"] : "profile-image"}
                src={
                  props.src
                    ? props.src
                    : props.userType === "corporate"
                    ? default_corporate_img
                    : default_user_img
                }
                className={`${
                  props.className ? props.className : ``
                } cursor-pointer ${getImageSize()}`}
                alt={props.alt ? props.alt : ""}
                onError={(_) => {
                  setIsUnableToDownloadImage(true);
                }}
              />
            )}

            {props.editMode === true && (
              <span
                className={`${
                  props.removeResponsive
                    ? ""
                    : "adaptive-edit-profile-picture-icon-position"
                } edit-profile-picture-icon-position ${
                  props.removeResponsive
                    ? ""
                    : "adaptive-edit-profile-picture-icon-size"
                } edit-profile-picture-icon-size flex-place-children-page-center box-shadow-default border-radius-100-percentage font-color-secondary background-white position-absolute text-align-center font-size-smaller`}
                onClick={() =>
                  profileInputElementRef.current &&
                  profileInputElementRef.current.click()
                }
              >
                <img src={edit} alt="edit-icon" width="100%" height="100%" />
              </span>
            )}
            {props.editMode === true && (
              <input
                ref={profileInputElementRef}
                // defaultValue={file}
                type="file"
                accept="image/*"
                name="editImage"
                id="profilePic"
                style={{ top: "0" }}
                className={`position-absolute left-0 overflow-hidden opacity-zero ${getEditIconSize()}`}
                onClick={(event) => {
                  event.target.value = "";
                }}
                onChange={(event) => {
                  fileHandler(event);
                }}
              />
            )}
          </div>
        )}

        {props.loading !== true &&
          (props.size === "medium" || props.showNotificationCount === true) &&
          typeof props.notificationCount === "number" &&
          props.notificationCount !== 0 && (
            <div
              data-cy={
                props["notification-count-data-cy"]
                  ? props["notification-count-data-cy"]
                  : "header-notification-count"
              }
              className={`height-and-width-14px box-shadow-default flex-place-children-page-center border-radius-50-percentage font-color-secondary background-white position-absolute bottom-0-point-4-em position-left-0 text-align-center font-size-smaller ${
                props.notificationCount > 9
                  ? "height-and-width-for-double-digit-number"
                  : props.notificationCount > 99
                  ? "height-and-width-for-triple-digit-number"
                  : "height-and-width-for-single-digit-number"
              }`}
            >
              {props.notificationCount > 99 ? "99+" : props.notificationCount}
            </div>
          )}
      </div>
    </>
  );
}

RoundedProfilePicture.propTypes = {
  /**
   * source of the profile picture image.if src is `undefined` or `null` it shows default profile image
   */
  src: PropTypes.node,
  /**
   * shows loading suspense animation for the profile picture
   */
  loading: PropTypes.bool,
  /**
   * size of the profile picture component
   * sizes are `smaller` `small` `medium` `large`
   */
  size: PropTypes.oneOf(["smaller", "small", "medium", "large"]),
  /**
   * class name for parent element of the component
   */
  className: PropTypes.string,
  /**
   * onClick event of the profile image
   */
  onClick: PropTypes.func,
  /**
   * profile image selector attribute for cypress testing
   * default value is `data-cy="profile-image"` loading state `data-cy="profile-image-loader"`
   */
  "data-cy": PropTypes.string,
  /**
   * alt attribute for the profile image. default value is "profile-picture"
   */
  alt: PropTypes.string,
  /**
   * which show profile picture edit image
   */
  editMode: PropTypes.bool,
  /**
   * used to show the notification count in profile picture
   * shows only if the value is `greater than 0` and the default value is 0. if the notification  count is greater than 99 then it shows `99+`
   */
  notificationCount: PropTypes.number,
  /**
   * to show notification count
   */
  showNotificationCount: PropTypes.bool
};

RoundedProfilePicture.defaultProps = {
  src: null,
  loading: false,
  size: "medium",
  className: "",
  onClick: undefined,
  "data-cy": "profile-image",
  alt: "profile_picture",
  editMode: false,
  notificationCount: 0,
  showNotificationCount: false
};
export default RoundedProfilePicture;
