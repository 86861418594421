import React from "react";
import "./Button.css";
import filter from "../../assets/filter.svg";
import "../../design-system.css";
import { rippleEffect } from "../../utils/Functions";
import PropTypes from "prop-types";
import { FilterIcon } from "../../assets/assets";

function Button(props) {
  const getClassName = () => {
    let className = "";
    if (props.disabled === true) {
      className +=
        " inherit-parent-width disabled-button-background padding-top-medium padding-bottom-medium font-color-secondary font-family-gilroy-medium border-radius-default  font-size-medium cursor-not-allowed max-height-44px";
      className += ` ${
        props.boxShadow === false || props.disabled === true
          ? "box-shadow-none"
          : "box-shadow-default"
      }`;
      className += ` ${props.className}`;
      return className;
    }

    className += ` inherit-parent-width padding-top-medium padding-bottom-medium font-family-gilroy-medium border-radius-default font-size-medium cursor-pointer `;
    // variant
    switch (props.variant) {
      // borderedSecondary => secondary
      case "secondary": {
        className +=
          "font-color-secondary background-white border-1px-e5e5e5 max-height-44px";
        break;
      }
      // filledRed => danger
      case "danger": {
        className += "font-color-white background-color-red max-height-44px";
        break;
      }

      case "transparent": {
        className +=
          " font-color-white button-background-transparent font-family-gilroy-medium max-height-44px";
        break;
      }

      case "primaryBorder": {
        className +=
          " font-color-primary background-color-white bordered-button-background font-family-gilroy-medium max-height-44px";
        break;
      }
      // filled => primary
      default: {
        className +=
          " background-color-primary font-color-white max-height-44px";
        break;
      }
    }
    className += ` ${
      props.boxShadow === false ? "box-shadow-none" : "box-shadow-default"
    }`;
    className += ` ${props.className}`;
    return className;
  };

  const FloatingButton = (props) => {
    return (
      <button
        data-cy={props["data-cy"] ? props["data-cy"] : "floating-button"}
        onClick={(event) => {
          props.onClick(event);
        }}
        autoFocus={props.autoFocus}
        className={`z-index-1 cursor-pointer ${props.className} ${
          props.positionFixed !== false
            ? "position-fixed"
            : props.activeIcon === true
            ? "position-relative"
            : ""
        } ${
          props.removeResponsive
            ? "adaptive-floating-button-size"
            : "floating-button-size"
        }  box-shadow-default background-white border-radius-16px flex-place-children-page-center flex-place-children-page-center ${
          props.position ? props.position : ""
        }`}
      >
        {props.src ? (
          <img
            src={props.src}
            alt={`${props.src}-img`}
            className={`${props.iconClassName}`}
            width="auto"
          />
        ) : (
          <FilterIcon
            className={props.iconClassName}
            width={props.removeResponsive ? "15" : "auto"}
          />
        )}
        {(props.activeIcon === true || props.activeIcon) && (
          <div className="background-primary border-radius-100-percentage padding-small position-absolute floating-button-active-icon-position-top-right" />
        )}
      </button>
    );
  };

  const DefaultButton = () => {
    return (
      <button
        data-cy={props["data-cy"] ? props["data-cy"] : "button-component"}
        type={props.type}
        className={getClassName()}
        onClick={(event) => {
          rippleEffect(event);
          if (props.loading === true) return;
          if (typeof props.onClick === "function") {
            props.onClick(event);
          }
        }}
        disabled={props.disabled || props.loading}
      >
        {props.loading !== true ? (
          props.text
        ) : (
          <div className="flex-place-children-page-center padding-vertical-small margin-top-smaller">
            <div
              className={`${
                props.disabled
                  ? "dot-pulse-dark"
                  : props.variant === "primaryBorder"
                  ? "dot-pulse-primary"
                  : "dot-pulse"
              }`}
            />
          </div>
        )}
      </button>
    );
  };
  return (
    <React.Fragment key={props.buttonKey}>
      {props.buttonCategory === "floating" ? (
        <FloatingButton {...props} />
      ) : (
        <DefaultButton />
      )}
    </React.Fragment>
  );
}

Button.propTypes = {
  /**
   * Button type
   * `submit` `reset` `button`
   * `note:not applicable for floating button`
   */
  type: PropTypes.oneOf([`submit`, `reset`, `button`]),
  /**
   * Button selector attribute for cypress testing
   *
   * Default  button `data-cy="button-component"`
   * floating button `data-cy="floating-button"`
   */
  "data-cy": PropTypes.string,
  /**
   * Defines the button category
   * `floating` `default`
   */
  buttonCategory: PropTypes.oneOf(["floating", "default"]),
  /**
   * Loading state shows dot pulse animation
   * Default state false
   * `note: loading prop is not available for floating button`
   */
  loading: PropTypes.bool,
  /**
   * Button box shadow
   * default state for box shadow is true
   * to disable box shadow => `boxShadow=false`
   */
  boxShadow: PropTypes.bool,
  /**
   * Button disabled state
   * Default state false
   */
  disabled: PropTypes.bool,
  /**
   * Button onClick function prop
   */
  onClick: PropTypes.func,
  /**
   * Button content
   */
  text: PropTypes.string.isRequired,
  /**
   * Button variant which you want
   * `floating` `danger` `secondary`
   */
  variant: PropTypes.oneOf([
    "danger",
    "secondary",
    "primary",
    "primaryBorder",
    "transparent"
  ]),
  /**
   * Button className which you want to add additionally
   */
  className: PropTypes.string,
  /**
   * Floating button position prop
   * default position is fixed
   */
  positionFixed: PropTypes.bool,
  /**
   * Floating button position class name prop
   */
  position: PropTypes.string,
  /**
   * Floating button activeIcon prop show green dot on top of button. Used to notify the button has some active state
   */
  activeIcon: PropTypes.bool,
  /**
   * Floating button icon src
   * default src is `filter.svg`
   */
  src: PropTypes.string,
  /**
   * Floating button icon class name
   */
  iconClassName: PropTypes.string,
  /**
   * buttonKey => key prop
   */
  buttonKey: PropTypes.node
};

Button.defaultProps = {
  disabled: false,
  buttonCategory: "default",
  loading: false,
  boxShadow: true,
  text: "",
  onClick: undefined,
  variant: "primary",
  className: "",
  positionFixed: true,
  position: "",
  activeIcon: false,
  src: filter,
  iconClassName: "",
  type: "button",
  buttonKey: "button"
};
export default Button;
